import { Icon } from "@types";
import React from "react";


const ZcoolIcon: Icon = ({ fill = "white" }) => (
  <svg
    t="1627746636442"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1619"
    width="16"
    height="16"
  >
    <path
      d="M422.485333 931.797333A337.194667 337.194667 0 0 1 85.333333 594.56C85.333333 439.04 190.592 301.226667 333.824 269.226667 501.12 231.68 577.194667 202.325333 635.733333 85.333333c42.581333 82.56 13.781333 158.293333 0 199.552 200.448-48.170667 271.829333-139.946667 289.024-151.381333 0 168.618667-52.522667 275.285333-103.210666 344.106667 62.976-2.389333 85.717333-20.650667 117.077333-25.045334-13.866667 61.781333-64 148.906667-184.746667 204.586667a337.28 337.28 0 0 1-331.434666 274.645333z m158.293334-275.285333c0 13.781333-2.261333 20.650667-17.194667 20.650667l-134.4 0.085333 126.293333-138.581333c36.693333-36.693333 36.693333-55.04 36.693334-101.888 0-14.250667-2.048-30.592 2.048-44.8 2.005333-6.144-2.048-8.192-8.149334-6.144-14.293333 4.053333-38.741333 4.053333-79.488 4.053333H323.2c-10.197333 0-14.293333-6.101333-10.197333-14.250667 0-2.048 0-8.149333-4.096-8.149333-26.453333 12.202667-32.597333 67.242667-30.549334 101.888 0 18.346667 10.197333 28.544 24.448 28.544h144.682667l-148.736 158.933333c-10.24 12.245333-20.394667 28.544-20.394667 50.944v44.842667c0 20.394667 12.245333 32.597333 34.645334 36.693333h255.488c23.68 0 39.808-9.941333 39.808-36.48v-48.170666c0-8.874667 0-41.301333-27.52-48.170667z"
      p-id="1620"
    ></path>
  </svg>
);

export default ZcoolIcon;
