import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo(props) {
  const { fill } = props;

  if (fill === "#000") {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="185px"
        height="40px"
        viewBox="0 0 185 40"
        enableBackground="new 0 0 185 40"
      >
        <image
          id="image0"
          width="185"
          height="40"
          x="0"
          y="0"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUMAAABGCAQAAAD8gD6LAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQflBxsICQf+0FNNAAAORklEQVR42u2deXRVxR3HP5OQBQIEQUBBQVZREEGFYtUuLvW4occV3ECt
oKKAxeWUilox1BURERcEgVNtj3Wt1n1BUVHAVhZFFBGUVRBjMIQQyPSPxJd3Z+42c+9LHnp/c3LO
m+/8fr+ZO/m+372z3HlCkkgiDS05Dd2ARBJJaJhIVkhCw0SyQBIaJpIFktAwkSyQhIaJZIE0UgGx
lmb1WP9W2b6huyCRhhchg4AMixQN3QWJNLzoN+Wt9Vp//daWSJZK8myYSBZIQsNEskAaRXeRWRFX
0ostlsbNeV6+Eqn2Zuwnl1jYDacHFalsHtuYKb/KQO80YU+K6Upb9qaQHLawgS9YT6n8zsrfcHry
YyjVXL5jHR/zrfw2hN9ubJRl3uVZT0M+YhxtrK0riERDRtCXcyzsyhitIHmMjbdjxACO4CgOd+md
Cj4R83mJj+R6Q6cbedDQYrF4mpfk/ACtYRQzzKdcKokyDcpkKgtWoRdfW/v/W5TGUcQ6KuhjYZnH
ewq0gX3j6zaOYUYItf8xmn0MPf/dojnVTKCrr9c7kNzpU64BWUdDCT34ytJ/NBpejUQy28r2RA0a
H0+XMYB/syu0+qdcQa6B9078YNWslZzs4/VWJJJHEB7lGpBZGs7jC0c+FA0l9GSFVX0RaEhLliOR
VNLXwjqPNxXoRzpE7T5yuINNxmZzOMCgjrGWjavgMk+fJbUfHvYo14BM0nCRhGJHDSFpKOEwhcDh
UhQajkp9nG5lf4oGlUTrPtrzoqXpBoaErqUpH1jWUs0ID58lqY9TXcs1IHM0XEShREIHFqaw0DSU
MMDi1nynqUHaP2NpKrOVgy08NOZ1BdpEO/vuozsfRej9Xfw5dE3nR6jlBFePJWmZKfqtWTfIFA0X
U5Cqo+4x2ICGEvqz2rBWexoOc2QfsPJxNtUKNMG6PZ35b+T/wXUh6xKhBkDuaSl7uHgscWTvp5mz
XF9TLotla8MiDnbkl9Bfbq+t4VGGpvCtsrmJW9GDE0LOawG04g25wKb5ogkLODAN+JHD5VJjL4W8
wa8d0AYODjPPpnnqwjMc5FG4hKWsZjWV5NCO9vTgQFp76F4j7w5VXxFn0pQdngrV5NKVvhzrUjZR
jtH8lSjTVWfKpxx5jbdxRMN/SBidll9cczuWSJjp0DSKhvWXlFgokUyz8jOYHQo00cJLS4/b8WYm
cBJtFe18+jCCdzycDYyxlwQnskyDd9JP0yxRgEuUcs0gKg2rmFTraVwtsojGKe+zFO2spCEt054L
f0rbrOYPc5inQOvpbOxlqis8lQN9bPZgCGtcCtbSLda+6sATGni3pqXS8EKlXDOISsNtdc8G3Izk
y7RIqD9xZCcN/+gK242XB7NTgW4z9HCCC7iR00NYduJZF/jJmHurDUsUaDmNFJ16p6FkOy1T3obX
rR0ot+OalIU0pDkfuxZ8z0EW3nLT5gVq0mo6GtgXM18D13NMSOs8prnAIShsdI0nU+UAKuivaDQA
DcfXjYnT/LqPvbKRhpd4Fj1k5e8cDRpnYH2pBm3k9wb2eS49/6EarSL32WuObDVDlfJ6p+FIl0a2
4nEP7ayjIYUs8iz83mQ1IuWxgAUKtFYdVvjYqoOTirCRMOUjn7kaeFrMvXatY2qqmrFKeQAN495v
OEpOdkGPYnDM9WROLqK3Z1kLbd9MCJGVlChQOy4JaTyQQxRkgnzDsP4dDGGtAo4RhdG7Kk0+ozIt
J9jfzDxeGrqTEJ7nr7HWkzERzbjCV+FC0dPC7Uu8ryBjxN4hWpPLqQq0hHvMq5cruU2BjqRHxK5y
SjXVjnylmXmcNPQiIXKXvJmbYr3sTMkgevmWFzLC3KmsZJICtQwVD4u16eH7ZfjJ+3R5nE8V5A8R
ekmXvWjsyO80M4+PhiO9SFgj8hZu9JmVzwoRBVyuQBuZqyCDxYFh/aXJy1o8vFy0DrQ6jLaO/Je8
YHdlcgtPK9DhETvLKX1If8NSsszMPC4ajpb3BXbFeObEeunxyzn0VZAbGItzvbMFl5o7lluZpkDt
Am7/gNaauXJtoI2XvE65I3+IiG3nvSjiJCeA6SsI2pjHZqQ8MowSV7FVA7NopEyhtnlgHUUIXlDQ
Upt1CIq0+cNvaBVgc68ju5OLI1xdkTLmLvXfL23k+3YF2MxeikY9jJRH+d+OxW/EfNFODGIyTeP6
/mVE9Fh4myyXkslUOdBirjJ3Lsu5X3li2ocxAUbO59RyvrS/OFlOqQPIp3MMfQaI4fxJgebIDabN
U5ltGg1HBXxT+rINyY8exVkTDWmuxcIvaCORIHhJKfnBbzXXs4ZG2hzg1/77sZUd56voFOkKJzuy
VWpEsvQ6ygU8SdPKcDQcJe/1/ab0ZgGNgSLr71q+KBD5lqmJyA1d0UAtFj5UsyVLSqYocaw5V5pf
idzJFAXal4t8TQocuR0GW9zcZLMjl4PRzKHIFU0d/4lCsYc4UzyvzQHA27xp2rRoj6mjA27Hh/Iu
4YngLk9QbDr8T0kbJjM9jKJorBFrFf/86aP8j3iX3zlKzxL3yuXG7Xmcq5VdgxeKh31e4tzlbGTE
vnRaS8V7kDTjrrR3r3dSTHeKXflzu6wwcQzRaBj0TNibeeRF8F8jx5t9axUJO0l7Br9SkFlyTVru
ToWGe3IFo0wbIyvF3cx0QJ0ZzERPA+eVC6IdO+WMrYJ8o7aXio9CzXU+yKvmTbO/KQdFwkNYEAMJ
jefjFQkVR0U+IxVoPQ+nZ+WL2vzheaKLRXueRD0DYqRo5an9jSPXmJaR+qKT0jObDO1nh5hw+5Br
pFmUBexpGPRM2Id5Zt+2BpVz6acgj8h1CjJeybcyj4Ygy5mgQB0Z4qnufO2gNd0jXeU+jlwlK43b
/le2+6osYZAsD+nOIXY0DIqEfViw+5BQNNGeC9ehvbEhX9NuNheJbhbVPY/6dsy1Yk8P3a8duTz6
R7jKbnR0AFWmNAQ5hyd9ip/jJLnKrnU2NBwdEAn7Mm83OBunTs7gUAWZJn9w0btLyTdluHllslwb
L+/lOV7+TMkfFuEqj8V5ru5nstTCy81sdMXLmMS58htDb2ndos7wBM0bBqyY0Fvb9O6XAucNKY00
rXVPiNk89eXwDeoaQK1mIS8r0HZ6W8y0tdD2H35FC1fNrkpfljHAen7vGQW429LPBA3awUN0D7AK
mDc0jVr1MUXjJ1WUUh06hheEWNs8XRsjT3VfA5DbxRQOBerWmPM4RXxi+kguS8UMJa7tx2XaViyA
tSxgQFq+GRfwgU23id9ynAK9Y+MHuI3TOMCBVDBLfm7prVbMaFg/UzR+soLhlIdudVO+8FcQ+dpG
1u94xFP9NU5GpO2sy6WKHMP5N4DHGIFz3+Iw8YD+ICArxDMOGsIgMU1+bNFv45QFhPW220xkmRjL
Mw6oORPEsdJ2brfWrRo+vW/KQct2h7DdOMab3pQ/tL0peXg/W4Ouj7cGj3qv1M7kusFVryvrFGgh
Rca1Xa5BY73O1grhrRFvaOAFATaxvYsSRMI+VFpckykNLf4JPr6LeF+BVtE+Pv8+Nedrb0KvYG9X
Tf0d5ccN6zqNcgUqZ/9Ire+nvIknWeV2ZEiaRUxrykGj491qiiYlJ2qbP2dE2NFnIHIH9ytQF853
VX0E9WY9WIwToWc4xOnMpokCTrdYiExv/QIeVaCONqvs6S5V3rpFw9EB346+VFh+sRowGrq8sbYm
2h4Wo9qb8ZkCLWNPV83xLuBs/+iTsj2b7zVwVfRzZ+nBZgX61u/t6zhuykG344OMpmicqSFpeJYG
3RGX71D16+fkXOGq11Y7FUEieZfDA/x3rD1jVU3DYmn99Ro000c7Mg2DBya2kVDSgDQkl3cVaFOc
p1OHaEFx7Wmydclj/yEDXL/oVUziCA/fHblY816THo2t9YsVqNKrNS40HKqUawZOGgZNVh+knVhl
lhqOhoM0KHQsJJcOtEpLbexaxRAF2Oi1Md8lctakcl7lRo6jF23Zg+a052guZYYHBSVv0zSe/pMw
VIPmkOehq9JQOXtWN6ij4RaOC2iIzRSNMzUQDcnTxsibaB3aOodbWcbSVPqUD+hi0Qr1tJybfHSv
8nG0lTUsYwmLWe57gPpbNfvJ40nkam/pSM730HXS8BW1r/0mgn+gpzgGgaQ6lXal/qoooETZw7b7
yKkuY+TQG59ktXifvyjgUMaZNkKWiQfSfodkNbN8dO8TuUz02HHYNNRbPm9xns0Rn54t2iVu4Wjl
/eTrxMtyc4DhawyW6g8uabzNsh+kyEQ0RDBHgVaaPReSw78UaK3NOf+0Sht+BD4UcBlbrC/62bDn
5hi1f7IGXe2qVxcN33Rrhw78Emion9E/wtiHPs6+0aot19R+2BDm+E2OcDkANDhVUGK/auLbnnba
xM0a9nPR+4mGc90PodeBnz0NXRajVtadyBjaS5E20l5vc84/LfkcifzpjNxA/b2YYvhEPl9/Uy6+
xEgNmuKiVUPD99xXin6ZNByoQZda+TlbWxe+1crPGCSlJrdMjmKStpzmnhYyhOJM/gPJ5xMF2qFv
R2M8krnu0/OS3YGG2xzZRVFpSDNtjLzUPBZKJDTSzrXeYjVebswqHjS2OowbauOoeyrnMc7KxPOg
1pIzNOgd9efReJAVfmdUZP8u6c2k/2TF90hrTzVyJL2UVdqp0uqHcuVOcR/dHbsrCxiknWUY7KdC
lGB4ZiHIhSwU0+lAPzrRiTZ0owBYy0ZW8CXLWcwaWS9HV8mnxBMc74D6cjLPOZA5POX307qZ+l2U
sGL4uyiJ/DxFp2HUaGMoMtq7t4n8LES/Ka+r32jY0B2QSDZIfQe/RBJxkbiPYE8kEQtJaJhIFkhC
w0SyQBIaJpIFktAwkSyQhIaJZIH8H358g+tl+PpdAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA3
LTI3VDAwOjA5OjA3KzA4OjAwhEncYAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNy0yN1QwMDow
OTowNyswODowMPUUZNwAAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1hZ2VtYWdpY2sub3JnvM8d
nQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0RVh0VGh1bWI6OkltYWdl
OjpIZWlnaHQANzCillzcAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OldpZHRoADMyM0TbHDsAAAAZdEVY
dFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVtYjo6TVRpbWUAMTYyNzMx
NTc0N/bgq54AAAASdEVYdFRodW1iOjpTaXplADM5MjNCQjm9fOUAAABGdEVYdFRodW1iOjpVUkkA
ZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNjI2Njc5MTQyODMwNjA2Nl81NF9b
MF1Mnt8WAAAAAElFTkSuQmCC"
        ></image>
      </svg>
    );
  }
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="185px"
      height="40px"
      viewBox="0 0 185 40"
      enableBackground="new 0 0 185 40"
    >
      <image
        id="image0"
        width="185"
        height="40"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUMAAABGCAQAAAD8gD6LAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAAHdElNRQflBxsICTBGbfZCAAAOB0lEQVR42u1deXRVxRn/vbysJCEQ
FjUoyGoUUIKgULT2FK1HQbQqSBBBqwICklBcTqm4QVIqQhEjIjucihZ3saJVlLIIktAKAQFZDEgI
OyEhhKxf/0jyeHfm3ndn5t733qXc35yck/nuzDdz5/3eNzPfLM9DcOEi3IgIdwVcuHBp6MIRcGno
wgFwaejCAXBp6MIBcGnowgGI5CSFSAxh+aVoFe4mcBF+eDi/YagdiZ5wN4GL8IPvlEtDWn5oS3Ph
ULhjQxcOgEtDFw5ApHUVQcZYdMFJxbyNsQJfWio9EVciXyHfSKSi3BeLwlksxs9BaJ1GaI4kdMAl
uAyxiMBJHMZuFKEYJ5T0jURnnBFK6cUJHMIPOIqjAqk74ghKjB87n4abMQktlXOXW6ThGKThAYV8
JchkJFGYaHPL9EIf3IzeOq1Tju3YhJXYjCJJnUcwRzLHVnyIldhkkmoEkjAiwHNiQwmFEiUE09CF
Dijr/4uAfuMQT4eonLop5Iyi9UxNDtMVluqiDX1pocDb/5cy6XJJzX9XaOVayqYOAbW+QkTTjJ9f
CDQEpdLPivqt0XA8EREtVcp7J1eXyTZRsBd9SjXCLfAjjSavhPa2dFqppfdR/wBapxAR0XzyOIOG
G2i3Ji5GQ1Bn2qNUnhUaJtMuIiKqoDSF3FH0DVOXM9TaMgUj6BU6Jt0Kq+lqiTImKn625TTKUGdW
fZq5TqDhFgIlaUoQpSGoB0NgMVihYYZPywKl/HdxtcmySMJW9Lliyx+m4cKlJNBGxVJqaYwJDYlm
h5uGWyiWQKDWlOeTidMQ1Euha54moZ/9MLb5tJTSdQoa4uhrpjbHKMUCCTvRZgutX0N/Ei5pqIVS
7jChIVEO3zWHzm+YjxtwDgBwADuVNGzEAzgQsvoOQWff/wkYpaChHHOhXRptjrHK9WmHd9HdwvtE
IBvPCKZ9G4uUS5mGpiZpxiCH3bfArymX2LK1YQuu08TPkxBYhId98lI0ltKbijsE/VoA0AyrkKtU
/0bIxTV+8TPojW3SWmKxCr/SSA7jOiE/G4v2+AhdDZ7lYxv2Yz8qEIEUtEIqrkELg7RPYbpQefG4
HwmoNHxeCy86IA236jybgQmcLItxV92PDzTxoHTK7xAo0y++tb47BoEWa1LKdMqhDCO4d5qnpCed
Khk9MxS0JBt0x8cpm/rRJUzqaOpGY2iNwWczwMZW8tCdtIMroZp6BuyUiYge1T63n4ZVNLNe06R6
yRaK82lfwqR2Jg2T/caFDTir5D+MoA2MniJqJ61ltm5Lz6ZrAuRpSsPpoE6uQupoa1u1puVcGdNN
aTgs2DQ8S019ul4kor1+lpB3uTqTho/pvpnafDmdqhk9UyU13KFTlyN0r0DOtvSxTt73bW6tlpTP
lLCLIsNNQ6JzlOzTNtJv7WCxTlon0rAx/aD7Xqeoq4I2r59foA77qY1E/iTaxNWkiPoK5o6ieTpv
IkJhmdCfqjT6y+kGORoGY6Y8DWW+/9/CL/X/LcTwIJQVDAxkplcNaKI0z63BNEbSGsMk8g9CT0Zy
FEOwSjB3FUbrzHqftXkvwWdYrYnHaKZ3IrDdGo7T+bY0o2UGqZ1nDWNpi+G7nZJajWgIMZTL6Cnk
phXGednJSbmwJWwI0bSWe5N7bG61p6nWT3stTQyvNczALB3pzUi3uZzg4RFca/isCbdvRgQVyGIk
KXhUMO8AzleYLWwJG1CJ4ShkZBMQa7WhNNiJCr+YB1fJZbeXhvokBFbgJVvLCR4SMTrg82F+Tm1x
rMR3jGQCLhPI58XdjCQff1Mofx+mMpKbkGqpnVjUolYTr5DLbicNjUgI1OBFvGDrawcLg9El4PNY
jFHQWoGZjCRZyB4mce7hNySc9/5Yhh8Zye/UG0kHlyJOE6+Wy24fDccZkrAOL+P5AF55ZyAGTzCS
I1jLSNKlh98A8AVnD58wXOk4jx64RBPfi88U3+wkPmQkva01FYNumhOWhB1y2e2iYSZeN00zmZlP
OQ8PII2RPIeJ0K53NsHjCppLMY+RpJh0/wC42qzlxnji+NrPfwEA3W2cLcejnybukT2CYA8NM/Ca
QKonmfVVpyGWm4AU4R2sx+eM9BF0VND+HjYzksfQzCRPiiZWw1lmGeRhlyaehCutNJYGz6OtJn4C
38gpsIOGGSbd8a+xCSkYjFlIsO3FgwHeFk5FGQizUKWRJuFJBe1leIMZMV2uswVAiy6Mhr0W3q4M
xZp4NNpZbrE6jMQfGclqHJbUYdlvmGHiUUqjs0R0xvF+w8b0H6Zuu6klgUAeWsk8OR1wNdcoRHI+
wAMm+7G1O84LqK2lN5yl0VbF+u4UQ4bOp9qPSxVkv6FZd3wtchEHIF65hGjEIFoxNIJXuJwBnC18
q35LFiGHsWONldZTqpHDSK7AIwFzxGhilYqz5AYc18QiJD2HXiRoPolYNMX9WMH5AIB/y3bJVg+I
Zpp0x9djnQQR9LEcSbLTfx9aYhYWCKWM44hVgHd9//8T6/AbzdOBeI0Za4lgGcYzuwaHYW6AQ5w1
mpjHYltqcxOj3QyJeNXv7HU1ktAJSbr8+avfCW1BWKGh2ZjwWmxAlAX9dbjdkr9f1El7H25kJEtw
0C82jaFhc4xGhnRtKjAdizWSdkjHDMP02jf3WLx2SmtbPYiWyl2MzUK+zjn4l3zV1DtlM0vYHbk2
kFDaH89AzI5GYxwjKcJcTfxzbpb6INor1Od97g6IcQHmy79oYnFIttQW2tlsNY5J5l8q4HD7Hk9J
WlkA6jQ0GxN2wwbJb1s4MYTbxTIfhxjJZCbeTMEaAmXIZiRtAuw80h47aIFOlt7yck2sAvuk6/6S
7yCHPvIxmPFOCkKNhmaWsBtyLyASNuLGhYd0Tmx8xXU2av7DFdzpmKfR3CCt9gBYFG6w8JYd0UYT
r5KmIbAa7wd4+gn6oUCtcio0zDSxhGnYcAHcjXMe9+F6RjIPp3XSvcrEEzBSobQybr58qeF8mT3B
2MPCW97K3Ku7k/EjiuFFHNGVl2AmhjCDCAnI01DERWPvJiIrMK9JJLdZweg6obXctUxjA2wKM8an
yGMko9FEN2UuM866Eb2UW4LdyrBRScteLORkVZiLnhiPs8p1k7ZaoXDRBEIVilEr/OWJEVjbvJeb
I882WAM4hxxcD/9rmaNwF7ZLD8mLsZCxa1diFLcVCwAKkashXiIeUqTPLbiNkaxR0gNMxT24WiMp
xxL8pKitHnI0DI2LJhD2YCTKhGudgN0mKaK5deQTmG+Y+iv0h8dvZ50XVYhQmBm+jTHMvsUReFNn
IFCOjxj7Nxjz8INCu01iFhCKlLeZlGAiPtJIGiMbtyr7dusgsZhntmzXnc5JLgSKLOYVa9J/b/MS
3iCuRs+GZOlwLHcn13O66TrQISZdHsVLl/YE95YTje7WEgiRtIrT95BJHttO5pmRsBtVSJNQnoYq
H4JxiKfvmPoUUKuQ0DCaOwm9hy7TTcmfUV4mWdY9VMZoKKOrLNW+J3MSj6jA71iwAg1FR1lms+ML
y0XTgDu5zZ8LLezok0El3mAk7TFUN+V8rrNOxySJqeW9WIpGjGyBwkKkP3K5035tLNzOAwhaw0yT
b0calStYwnBbQ/7E2kGLe1hkQiLtZErfQc11U07WabelJtanIQyiU1zeAhvunU2l44zWowFPX9tg
DTN1dlH4o6ujXDTiuBs3MZJlQbkmXR+l3FpyKgbppszRucTpIaww3cbfBlPwDx1XULa6f8+Hndzp
6xaWjr2ZWkPziYmqJQyvNfTSOqYux2y9ndo8JNXfJnseRvsPe3EXkBDV3RbUx0B3G/oDp70Oi2yr
/VZGc4VhbXhr+LD2uZnrw8xF0xUbg+yiCRYGog8jWSRsJbxopVk79aJMYS31NLKZ/TYxBuPrjRiN
tzhpJDLwONZjHTagCMdQiRok4iq0R2/0MVh/XqO0c1y/9jOYEWI0snAbs1PdCMTFDa3hSbrN1BLK
u2icYQ2juDnyMWohnDuCptAO2uYLP9JGaq9QC/a2nBcCpH0yQCuW0kHaQfm0lXYFvED92/r95PYE
L33GlTBUyBp+ybZ1IGt4Gp3RFx4Qan2hxvdXhRhkMXvYLhzcrTNHFt/4VIvv8GdG9jAmSdeiBG/6
LRzux5IAaV+HFzMMdhwmCJ3y+RYPKl3xaYQavIzfMueTn8EXzC5vHl8hnfvBpQDWMBQIjzX00Gqm
Hvskx4UR9B6joVDpnv9mfpeyvWKaehSdVG7pj4XvzZEJs7hyxptYw2/06nFx/mZef9zCSKZLzh5r
sZyRpPhdpSyOEz4LKPL7THNwF7YrlHIO2fi9wd4Ya5jKrdpPCHjwdB2G6tbjIrSG/GLUPr8bGUVD
PDfTLlK65z+ZfiIi8t2RaxYupRzJEfkmnZNy9oVxXHk5htZwvcFK0UVJwwFcLR5X0jOIWxeeoqRn
AhEVS3WZN9NMbjlNH3k0nJKCSEJQNG1nyqykXlyqyUS01sA9f0HQ8Kwm/RbLNEzk5sjbFGwhCBTJ
3Wt9Umm+HEcFNEc6Vw96rt6O6qOM3qaBQRkPsuE+ruw13M+jzaE91MxYh/N3SR/X/GTFKZCypjrc
hC7MKu1sxR/Krcbr6KTZXRmDwdxdhuYoR5b0nYVAHvKwAK3RE23RFi3RETEACnEEe7AXu7AVB0N0
ddUHWI7bNZI09McnGslqfBBo72ewfhdFFLK/i+Li/xI8Da1aG+kahLsJXIQffKd8KMTW0IULHWvo
wkXIcXG6r104DC4NXTgALg1dOAAuDV04AC4NXTgALg1dOAD/A7sAbXmeYWupAAAAJXRFWHRkYXRl
OmNyZWF0ZQAyMDIxLTA3LTI3VDAwOjA5OjQ4KzA4OjAw9kuicwAAACV0RVh0ZGF0ZTptb2RpZnkA
MjAyMS0wNy0yN1QwMDowOTo0OCswODowMIcWGs8AAAAgdEVYdHNvZnR3YXJlAGh0dHBzOi8vaW1h
Z2VtYWdpY2sub3JnvM8dnQAAABh0RVh0VGh1bWI6OkRvY3VtZW50OjpQYWdlcwAxp/+7LwAAABd0
RVh0VGh1bWI6OkltYWdlOjpIZWlnaHQANzCillzcAAAAF3RFWHRUaHVtYjo6SW1hZ2U6OldpZHRo
ADMyM0TbHDsAAAAZdEVYdFRodW1iOjpNaW1ldHlwZQBpbWFnZS9wbmc/slZOAAAAF3RFWHRUaHVt
Yjo6TVRpbWUAMTYyNzMxNTc4OMrq+QMAAAASdEVYdFRodW1iOjpTaXplADY0NDNCQrSLdjsAAABG
dEVYdFRodW1iOjpVUkkAZmlsZTovLy9hcHAvdG1wL2ltYWdlbGMvaW1ndmlldzJfOV8xNjI2Njc5
MzEzNjE2Mjk1M185M19bMF3nuXqGAAAAAElFTkSuQmCC"
      ></image>
    </svg>
  );
}
